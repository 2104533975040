<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->

		<div class="row">
			<div class="col-md-12">

				<div class="card mt-3 shadow">
					<div class="card-header">
						<div class="float-left mt-2">
							<h5>福利卡批次库存</h5>
						</div>
						
						<div class="float-right">
							<button @click="back" type="type" class="btn btn-outline-dark">
								<i class="icon icon-chevron-circle-left"></i>返回
							</button>
						</div>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="">

							<div class="form-row">
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">归属企业</label>
									<select class="form-control" v-model="giftcard.dept_id">
										<option value="">选择企业</option>
										<template v-for="(item,index) in departmentlist">
										<option v-bind:key="index"
											:value="item.id"  v-if="item.enabled==1">
											{{item.department_name}}</option>
										</template>
									</select>
								</div>
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">福利卡分类</label>
									<select class="form-control" v-model="giftcard.category">
										<option value="">选择分类</option>
										<template v-for="(item,index) in categorylist">
										<option v-bind:key="index"
											:value="item.value">
											{{item.name}}</option>
										</template>
									</select>
								</div>
								
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">卡类型</label>
									<select class="form-control" v-model="giftcard.card_type">
										<option value="">选择类型</option>
										<option v-bind:key="index" :value="item.value"
											v-for="(item,index) in cardtypelist">{{item.name}}</option>
									</select>
									<div class="text-muted s-12">
										实体卡下发时，不会生成电子卡链接
									</div>
								</div>
								
								
							</div>
							
							

							<div class="form-row">
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">服务费方案</label>
									<select class="form-control" v-model="giftcard.expense_type">
										<option v-bind:key="index" :value="item.value"
											v-for="(item,index) in expensetypelist">{{item.name}}</option>
									</select>
								</div>
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">服务费费率(%)</label>
									<input v-model="giftcard.expense_rate" type="number" class="form-control" 
									placeholder="请输入服务费费率">
									<div class="text-muted s-12">
										0~100
									</div>
								</div>
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">面额(元)</label>
									<input v-model="giftcard.price" type="number" class="form-control" 
									placeholder="请输入制度福利金额">
									<div class="text-muted s-12">
										服务费方案为企业付，实际金额等于面额；为员工付，实际金额等于面额减服务费。
									</div>
								</div>
								
								
								
								
							</div>
							
							<div class="form-row">
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">数量 </label>
									<input v-model="giftcard.qty" type="number" class="form-control"
										placeholder="请输入建卡数量">
									<div class="text-muted s-12">
										
									</div>
								</div>
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">关联制度</label>
									<!-- <input  v-model="giftcard.ins_id" type="text" class="form-control" > -->
									<div class="input-group">
									  <input v-model="institution.institution_name" type="text" class="form-control" readonly placeholder="关联制度" aria-describedby="basic-addon2">
									  <div class="input-group-append" @click="onSearchIns">
									    <span class="input-group-text" id="basic-addon2">选择制度</span>
									  </div>
									</div>
									<small class="form-text text-muted">
										用户核销时，将以此制度发放额度
									</small>
								</div>
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">实体卡印刷模板</label>
									<br>
								   <select class="form-control" v-model="giftcard.cover">
									<option value="">选择模板</option>
									<template v-for="(item,index) in coverlist">
									<option v-bind:key="index"
										:value="item.value">
										{{item.name}}</option>
									</template>
								   </select>
									<small class="form-text text-muted">
										
									</small>
								</div>
								
								<!-- <div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">有效期</label>
									<div>
									<date-picker v-model="giftcard.effective_start_date" format="YYYY-MM-DD" value-type="format"
										input-class="form-control" :editable="false" :clearable="false">
									</date-picker>
									</div>
									<div class="text-muted s-12">
										最早是当日
									</div>
								</div> -->
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">有效期至</label>
									<div>
									<template v-if="is_longtime==0">
										<date-picker v-model="giftcard.expiry_date" format="YYYY-MM-DD" value-type="format"
											input-class="form-control" :editable="false" :clearable="false">
										</date-picker>
									</template>
									<span class="ml-2">
										<input type="checkbox" @change="onChangeEndDate" v-model="is_longtime" value="1" id="chk_end_date" name="chk_end_date">
										<label for="chk_end_date">长期</label>
									</span>	
										
									</div>
									<div class="s-12">
									</div>
								</div>
							</div>
							
							<div class="form-row">
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">备注</label>
									<br>
									
									<input  v-model="giftcard.remark" type="text" class="form-control" >
									   
									<small class="form-text text-muted">
										<!-- 输入一些便于记忆的备注 -->
									</small>
								</div>
							</div>
							
						</div>
					</div>

				</div>
				
				
			</div>
		</div>

		
		
		<div class="form-group mt-3">
			<button v-if="giftcard.id>0" @click="deleteGiftcard" type="button" class="btn btn-dark"> 删除 </button>
			<button @click="save" :disabled="disabled" type="button" class="btn btn-primary ml-2"> 保存 </button>
		</div>

		<!-- //main content -->


		<!-- modal -->

		<!-- 查询制度 -->
		<div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">选择关联制度</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="row">
								<div class="col">
									<input v-model="query" type="text" placeholder="查询制度..." class="form-control" />
								</div>
								<div class="col">
									<div class="float-right">
										<button @click="searchInstitution" type="button" class="btn btn-dark"> 查询
										</button>
									</div>
								</div>
							</div>
							<template v-if='institution_list.length > 0'>
								<hr>
								<!-- <div class="card">
									<div class="card-body no-m p-3 grey lighten-2">
										<div class="form-check">
											<label class="form-check-label">
												<input v-model="check_all"  @click.stop="checkAll" class="form-check-input" type="checkbox"> 所有
											</label>
										</div>
									</div>
								</div> -->
							</template>
							
							<template v-for="(item, index) in institution_list">
								<div class="card mt-1 mb-1 border-0" v-bind:key="'t_'+index">
									<div class="card-body no-m p-1 pl-3">
										<div class="row" v-if="index==0">
											<div class="col-md-1">
											</div>
											<div class="col-md-2">
												<span class="font-weight-bold">制度分类</span>
											</div>
										
											<div class="col-md-5">
												<span class="font-weight-bold">制度名称</span>
											</div>
										
											<div class="col-md-2">
												费用类型
											</div>
											<div class="col-md-2">
												金额
											</div>
										</div>
									</div>
								</div>		
								<div class="card mt-1 mb-1" v-bind:key="index">
									<div class="card-body no-m p-1 pl-3 bg-light">
										<div class="row">
											<div class="col-md-1 pt-2">
												<input v-model="checkedIds" :value="index" :id="index" type="radio"
													:disabled="item.enabled == 0">
											</div>
											
											<div class="col-md-2 pt-2">
												<div class="d-none d-lg-block">
												<!-- {{item.category}} -->
												<template v-if="categoryMap.hasOwnProperty(item.category)">
													{{categoryMap[item.category]}}
												</template>
												</div>
											</div>

											<div class="col-md-5">
												<div class="d-none d-lg-block">
												{{item.institution_name}}
												<br>
												{{item.institution_id}}
												<!-- <i class="icon icon-copy" /> -->
												</div>
											</div>

											<div class="col-md-2 pt-2">
												<div class="d-none d-lg-block">
													{{expenseTypeMap[item.expense_type]}}
													>> 
													<small class="text-muted">{{subexpenseTypeMap[item.expense_type_sub_category]}}</small>
													
												</div>
											</div>
											<div class="col-md-2 pt-2">
												<div class="d-none d-lg-block"><span
														class="r-3  font-weight-bold">
														{{item.quota_total}}
														元
														</span>
												
												<!-- <button 
												@click.stop="refresh(index)" type="button"
													class="btn btn-link btn-xs"> <i class="icon-refresh"></i> </button> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveSearchRes" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>


		



		<!-- //modal -->
	</div>
</template>

<script>
	// import VueUploadImgs from '../components/VueUploadImgs.vue'
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';

	export default {
		data() {
			return {
				title: '创建商品',

				token: '',

				giftcard: {
					dept_id: '',
					category: '',
					expense_type: '',
					expense_rate: '',
					cover: '',
					qty: '',
					remark: '',
					expiry_date: '',
					ins_id: 0,
					card_type: '',
				},

				disabled: false,

				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				
				expensetypelist: [
					{name: '选择服务费方案', value: ''},
					// {name: '通用(默认)', value: 'DEFAULT'},
					// {name: '餐饮', value: 'MEAL'},
					// {name: '商城', value: 'MALL'},
					// {name: '公交地铁', value: 'METRO'},
					// {name: '用车', value: 'CAR'},
					// {name: '话费充值', value: 'PREPAYRECHARGE'},
					// {name: '酒店', value: 'HOTEL'},
					// {name: '火车票', value: 'TRAIN'},
					// {name: '机票', value: 'PLANE'},
					{name: '企业付', value: 'ENT'},
					{name: '员工付', value: 'STAFF'},
				],
				
				institution_list: [],
				
				// current_date: '',
				is_longtime: 0,
				
				query: '',
				check_all: false,
				checkedIds: [],//指定商户选中的商户的index
				
				//本地分类
				// categorylist: [
				// 	{name: '选择分类', value: ''},
				// 	{name: '弹性福利', value: 'DEFAULT'},
				// 	{name: '餐食外卖', value: 'MEAL'},
				// 	{name: '交通出行', value: 'CAR'},
				// 	{name: '工会福利', value: 'COMMON'},//OTHER
				// ],
				categorylist: this.GLOBAL.categorylist,
				coverlist: [
					// {name: '选择模板', value: ''},
					{name: '大福卡', value: 'DEFAULT'},
					{name: '小福餐饮卡', value: 'MEAL'},
					{name: '小福交通卡', value: 'CAR'},
					{name: '小福工会卡', value: 'COMMON'},
				],
				institution: {institution_name: ''},
				categoryMap: [],
				expenseTypeMap: {
					'DEFAULT': '通用',
					'MEAL':'餐饮',
					// 'MALL':'商城',
					// 'METRO': '公交地铁',
					// 'PREPAYRECHARGE': '话费充值',
					// 'HOTEL': '酒店',
					// 'TRAIN': '火车票',
					// 'PLANE': '机票',
					// 'CAR': '用车',
				},
				subexpenseTypeMap: {
					'DEFAULT': '通用',
					'REACH_SHOP':'到店',
					'TAKE_AWAY':'外卖',
					// 'ONLINE_MALL':'线上商城',
					// 'METRO': '公交地铁',
					// 'ONLINE_PREPAY_RECHARGE': '线上充值',
					// 'REACH_SHOP_HOTEL': '到店住宿',
					// 'ONLINE_HOTEL': '在线订酒店',
					// 'ONLINE_TRAIN': '火车票预订',
					// 'ONLINE_PLANE': '机票预订',
					// 'CAR': '用车',
				},
				gcs_id: '',
				nonce_str: '',
				
				cardtypelist: [
					// {name: '选择类型', value: ''},
					{name: '实体卡', value: '0'},
					{name: '电子卡', value: '1'},
				],
			}
		},

		components: {
			// VueUploadImgs
			DatePicker
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			// let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			// console.log(lsLoginData.logined_outlet_name)
			// // self.outlet_id = lsLoginData.logined_outlet_id;
			// console.info('--- this.$store.state ->', this.$store.state)
			// self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			// self.outlet_name = lsLoginData.logined_outlet_name;
			// self.user_name = lsLoginData.user_account;

			// self.setting = JSON.parse(localStorage.getItem('SETTING'));

			// self.institution_list = JSON.parse(localStorage.getItem('OUTLETLIST'));
			// console.log('--- outlet list ->', self.institution_list)

			// //指定店铺
			// // let selectedOutletId = getQueryString("outlet_id");
			// console.log('--- this.$route>', this.$route)
			// let selectedOutletId = this.$route.params.outlet_id;
			// if (selectedOutletId) {
			// 	self.outlet_id = selectedOutletId;
			// }

			// //是否编辑
			// let selectedProductBarcode = this.$route.query.no;
			// if (selectedProductBarcode) {
			// 	var _params = this.$route.query;
			// 	// self.selectedOutletId = _params.outlet_id;
			// 	self.resetProduct(_params);
			// }
			
			//构造categoryMap
			self.categoryMap = [];
			for(let i=0; i<this.GLOBAL.categorylist.length; i++){
				let et = this.GLOBAL.categorylist[i];
				self.categoryMap[et.value] = et.name;
			}
			
			//3.取得参数
			let id = this.$route.query.id;
			let nonce_str = this.$route.query.nonce_str;
			
			
			if(id){
				console.log('--- id, nonce_str',id, nonce_str)
				self.gcs_id = id;
				self.nonce_str = nonce_str;
			}
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }

			self.init(()=>{
				if(self.gcs_id){
					self.initData();
				}
			});
		},
		methods: {
			init(callback) {
				let self = this;
			
				//
				this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
						params: {
							token: self.token,
							// dept_id: self.dept_id,
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.departmentlist = data.data.departments;
							
							for (let i = 0; i < self.departmentlist.length; i++) {
								let dept = self.departmentlist[i];
								self.deptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.deptmap);
							
							// self.giftcard.effective_start_date = data.data.current_date;
							
							if (typeof callback == 'function') {
							    callback()
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},
			
			initData() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getGiftCardStockDetails', {
						params: {
							token: self.token,
							outlet_id: self.outlet_id,
							id: self.gcs_id,
							nonce_str: self.nonce_str,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {

							if (data.data.result_code == 'success') {
								self.giftcard = data.data.gift_card;
								console.log('---- giftcard ->',self.giftcard);
								
								self.institution = {
									id: self.giftcard.ins_id,
									institution_id: self.giftcard.institution_id,
									institution_name: self.giftcard.institution_name
								}
								
								self.is_longtime = self.giftcard.expiry_date == '2099-12-31'?1:0;
							} else {
								// alert('该店铺没有商品分类');
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
								}).goAway(2000)
							}

							// __LIST = data.data;
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END

			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init();
			},


			onstatechange(state) {
				console.log('--- state  -> ', state)
			},
			
			back(){
				let self = this;
				self.$router.push({
					path: 'giftcardstocks',
					params: {
						outlet_id: self.outlet_id
					}
				})
			},

			save() {
				let self = this;
				console.log(self.giftcard);
				// return;


				//0.构建giftcard对象数据
				self.buildGiftcard();
				console.log('--- giftcard after build->', self.giftcard)

				//1.校验
				if (self.giftcard.dept_id == '') {
					self.$toasted.error('请选择归属企业', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (self.giftcard.category == '') {
					self.$toasted.error('请选择分类', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (self.giftcard.card_type == '') {
					self.$toasted.error('请选择卡类型', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.giftcard.expense_type) {
					self.$toasted.error('请选择服务费方案', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.giftcard.expense_rate) {
					self.$toasted.error('服务费费率不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (self.giftcard.expense_rate<0 || self.giftcard.expense_rate>100) {
					self.$toasted.error('服务费费率在0~100之间', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.giftcard.price) {
					self.$toasted.error('面额不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (self.giftcard.price <= 0) {
					self.$toasted.error('金额必须大于0', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.giftcard.qty) {
					self.$toasted.error('数量 不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (self.giftcard.qty <= 0) {
					self.$toasted.error('数量 必须大于0', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.giftcard.institution_id) {
					self.$toasted.error('关联制度未设置', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (self.giftcard.card_type == 0 && !self.giftcard.cover) {
					self.$toasted.error('实体卡印刷模板', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.giftcard.expiry_date) {
					self.$toasted.error('有效期不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.saveGiftcard();
			},

			//保存提交前，构建giftcard：处理standard使用规则：规则因子等
			buildGiftcard() {
				let self = this;
				
				//处理关联制度
				self.giftcard.ins_id = self.institution.id;
				self.giftcard.institution_id = self.institution.institution_id;
				self.giftcard.institution_name = self.institution.institution_name;
			},

			//保存制度
			saveGiftcard() {
				let self = this;

				self.disabled = true;

				// 组装数据
				let postData = self.giftcard;

				postData['token'] = self.token;

				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}

				let url  = self.giftcard.id?'updateGiftCardStock':'addGiftCardStock'

				//调用接口
				this.axios.post(this.GLOBAL.baseURI + url,
						postData2
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log('--- error->', error);
						// alert('保存失败')
						self.$toasted.error('保存失败', {
							position: 'top-center',
						}).goAway(2000)

						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							if (data.data.result_code == 'success') {
								self.$toasted.success('保存成功', {
									position: 'top-center',
								}).goAway(2000)

								self.$router.push({
									path: 'giftcardstocks',
									params: {
										outlet_id: self.outlet_id
									}
								})
							}
							else{
								self.$toasted.error( data.data.result_msg ,{
									position: 'top-center',
								}).goAway(2000)
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)

							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
				//END
			},

			deleteGiftcard() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'delGiftCardStock', {
						params: {
							token: self.token,
							// outlet_id: self.outlet_id,
							id: self.giftcard.id,
							nonce_str: self.giftcard.nonce_str, 
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						if (data.code == 200) {
							// self.$toasted.success('删除成功', {
							// 	position: 'top-center',
							// }).goAway(2000)
							
							// self.$router.push({
							// 	path: 'giftcardstocks',
							// 	params: {
							// 		outlet_id: self.outlet_id
							// 	}
							// })
							
							if (data.data.result_code == 'success') {
								self.$toasted.success('删除成功', {
									position: 'top-center',
								}).goAway(2000)
							
								self.$router.push({
									path: 'giftcardstocks',
									params: {
										outlet_id: self.outlet_id
									}
								})
							}
							else{
								self.$toasted.error( data.data.result_msg ,{
									position: 'top-center',
								}).goAway(2000)
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message);
							// Vue.toasted.error( data.message, {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}

					});
				//END
			},

			
			onSearchIns(){
				
				let self = this;
				console.log('--- onSearchIns ---',self.giftcard)
				
				//校验
				if (self.giftcard.dept_id == '') {
					self.$toasted.error('请选择归属企业', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (self.giftcard.category == '') {
					self.$toasted.error('请选择分类', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				
				//初始化
				self.query = '';
				self.institution_list = [];
				self.institution = {institution_name: ''};
				
				self.searchInstitution();
				
				$('#searchModal').modal('show');
			},
			
			onChangeEndDate(){
				let self = this;
				
				if(self.is_longtime==1){
					self.giftcard.expiry_date = '2099-12-31'//生效结束日期为长期时，设为2099年
				}
				else{
					self.giftcard.expiry_date = '';
				}
			},
			
			searchInstitution(){
				let self = this;
				
				self.current_page = 1;
				self.page_size = 20;
				self.initInsData();
			},
			
			initInsData() {
				let self = this;
			
				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getInstitutionList', {
						params: {
							token: self.token,
							dept_id: self.giftcard.dept_id,//self.dept_id,
							category: self.giftcard.category,
							// start_date: self.start_date,//有效期
							// end_date: self.end_date,
							query: self.query,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
			
							self.institution_list = data.data.institution_list;
							
							self.start_date = data.data.start_date;
							self.end_date = data.data.end_date;
							
							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)
							
							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;
							
							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			
			},
			
			checkAll() {
				let self = this;
				self.check_all = !self.check_all;
				console.log('--- check all ---', self.check_all)
			
				if (self.check_all) {
					for (var i = 0; i < self.institution_list.length; i++) {
						if (self.institution_list[i].enabled == 0) continue;
						// self.checkedIds.push(self.institution_list[i].id);
						self.checkedIds.push(i);
					}
				} else {
					self.checkedIds = [];
				}
			},
			
			//暂存选择的指定商户
			saveSearchRes() {
				let self = this;
				console.log('--- checkedIds ---', self.checkedIds)
				
				if(self.checkedIds.length==0){
					self.$toasted.error( '请选择制度', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				self.institution = self.institution_list[self.checkedIds];
				
				console.log('--- institution @ saveSearchRes>', self.institution);
				
				$('#searchModal').modal('hide');
			},

		},
	}
</script>

<style scoped>

</style>
